import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
// import { USER } from '../../../configs/exports';

const SidebarContent = ({
  onClick, changeToLight, changeToDark, sidebarCollapse,
}) => {
  const hideSidebar = () => {
    onClick();

  };

  return (
    <div className="sidebar__content">

      <ul className="sidebar__block">
        <SidebarLink
          title="SenderIDs"
          route="/shortcodes"
          onClick={hideSidebar}
        />

        <SidebarLink
          title="Users"
          route="/staffs"
          icon='users'
          onClick={hideSidebar}
        />
{/* 
        <SidebarCategory title="Settings" icon="cog" sidebarCollapse={sidebarCollapse}>


          <SidebarLink
            title="SenderIDs"
            route="/shortcodes"
            onClick={hideSidebar}
          />

          <SidebarLink
            title="Users"
            route="/staffs"
            onClick={hideSidebar}
          />

        </SidebarCategory> */}

      </ul>



      <ul className="sidebar__block">
        <SidebarLink title="Log Out" icon="exit" route="/log_in" />
      </ul>

    </div>

  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
