import React, { useState, Fragment, useEffect } from 'react';

import ReactDatatable from '@ashvin27/react-datatable';

import axios from 'axios';
import "../../_assets/css/file.css"
import { Card, CardBody, Button, Table } from 'reactstrap';

import { baseURL, TOKEN, logout, errorToast, successToast, ToastTable, USER } from '../../configs/exports';
import Modal from 'react-modal';
import { Form } from 'react-bootstrap';
import { MDBCloseIcon } from "mdbreact";
import * as moment from 'moment';
// import Select from "react-select";
import 'react-phone-number-input/style.css'


const ShortCodes = () => {
    const [senderids, setSenderIDData] = useState([])
    const [isLoading, setLoad] = useState(true)
    const [open, isOpen] = useState(false)
    const [openAdd, isOpenAdd] = useState(false)
    const [queryString, setQueryString] = useState("")
    const [recruitment_id, setRecruitmentID] = useState("")
    //use

    // eslint-disable-next-line
    const [user, setUser] = useState([])

    // eslint-disable-next-line
    const [user_id, setUserID] = useState("")
    const [name, setName] = useState('')
    const [api_key, setAPIKey] = useState('')
    const [access_key, setAccessKey] = useState('')
    const [client_id, setClientID] = useState('')


    useEffect(() => {
        setLoad(true)

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': TOKEN
            },
        };
        let url_user = baseURL + "user?" + queryString;

        let url_sender_id = baseURL + "sender_id?" + queryString;
        // alert(url)
        axios.all([
            axios.get(url_user, config),
            axios.get(url_sender_id, config),

        ]).then(axios.spread((userResponse, senderIDResponse) => {
            let data = [];


            if (senderIDResponse.data.data.length > 0) {
                setLoad(false)
                for (let i = 0; i < senderIDResponse.data.data.length; i++) {
                    let date = { dates: (moment(senderIDResponse.data.data[i].created_on).format('DD/MM/YYYY')) };
                    data.push(Object.assign(date, senderIDResponse.data.data[i]));
                    setLoad(false)
                }
                setSenderIDData(data)
            } else {
                setLoad(false)
                setSenderIDData(data)
            }
            setUser(userResponse.data.data)
        }))

    }, [queryString]);


    const config = {
        key_column: "tsc",
        length_menu: [10, 20, 50, 100, 200, 500],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        page_size: 100,
        show_length_menu: true,
        show_info: true,
        language: {
            loading_text: "Please be patient while data loads...",
            filter: "Search here ...",
            no_data_text: "No data was found",
            pagination: {
                next: <span>&#9658;</span>,
                previous: <span>&#9668;</span>
            }
        }
    }
    const columns = [
        {
            key: "name",
            TrOnlyClassName: 'tsc',
            text: "SenderID",
            className: "tsc",
            align: "left"
        },
        {
            key: "user_name",
            TrOnlyClassName: 'tsc',
            text: "Owner",
            className: "tsc",
            align: "left"
        },

        {
            key: "dates",
            TrOnlyClassName: 'tsc',
            text: "Created On",
            className: "tsc",
            align: "left"
        }
        ,
        {
            key: "action",
            text: "Options",
            TrOnlyClassName: 'cell',
            className: "cell",
            width: 250,
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center" >
                        <button className="btn btn-primary btn-sm"
                            title="EditCategory"
                            style={
                                { marginRight: '10px' }}
                            onClick={() => { onModify(record) }} >

                            Edit
                        </button>

                        {/* <button className="btn btn-danger btn-sm"
                            title="Delete Category"
                            style={
                                { marginRight: '10px' }}

                            onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) onSubmitDelete(record) }} >

                            Delete
                        </button> */}


                    </Fragment>
                );
            }
        }

    ];
    const closeModal = (e) => {
        isOpen(false)
    }

    const onModify = (data) => {

        // alert(data.ui_name)
        setRecruitmentID(data.id)
        setName(data.name)
        setAPIKey(data.api_key)
        setClientID(data.client_id)
        setAccessKey(data.access_key)
        isOpen(true)

    };

    const closeModalAdd = (e) => {
        isOpenAdd(false)
    }


    // const onSubmitDelete = (record) => {
    //     // alert(record.id)
    //     setLoad(true)

    //     axios.delete(baseURL + `user/${record.id}`, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Accept': 'application/json',
    //             'Authorization': TOKEN
    //         },
    //     }).then((response) => {
    //         if (response.data.status) {
    //             successToast(response.data.message)
    //             window.setTimeout(() => {
    //                 // isSuccess(false)
    //                 setQueryString(require("randomstring").generate({
    //                     length: 1,
    //                     charset: 'alphanumeric',
    //                     capitalization: 'lowercase'
    //                 }))
    //             }, 2000);
    //         } else {
    //             errorToast(response.data.message)
    //             window.setTimeout(() => {
    //             }, 2000);
    //         }
    //     })
    //         .catch((error) => {
    //             if (error.response.data.status_code === 401) {
    //                 logout()
    //             }
    //             errorToast(error.response.data.message)
    //             window.setTimeout(() => {
    //             }, 2000);
    //         })
    // }


    const onSubmitUpdate = (record) => {
        record.preventDefault();
        // alert(record.id)


        let formData = {
            "name": name.replaceAll(" ", ""),
            "api_key": api_key,
            "client_id": client_id,
            "access_key": access_key,
            "user_id": USER.user_id,
            "id": recruitment_id
        }
        setLoad(true)
        axios.post(baseURL + 'sender_id_update', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then((response) => {
            if (response.data.success) {

                successToast(response.data.message)
                setLoad(false)
                window.scrollTo(0, 0)
                window.setTimeout(() => {
                    isOpen(false)
                    setQueryString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                }, 2000);
            } else {
                errorToast(response.data.message)
                window.setTimeout(() => {
                }, 2000);
            }
        })
            .catch((error) => {
                if (error.response.data.status_code === 401) {
                    logout()
                }
                errorToast(error.response.data.message)
                setLoad(false)
                window.setTimeout(() => {
                }, 2000);
            })
    }


    const onSubmit = (record) => {
        record.preventDefault();

        let formData = {
            "name": name.replaceAll(" ", ""),
            "api_key": api_key,
            "client_id": client_id,
            "access_key": access_key,
            "user_id": USER.user_id,
            "id": recruitment_id
        }
        setLoad(true)

        axios.post(baseURL + 'sender_id', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then((response) => {
            if (response.data.success) {
                successToast(response.data.message)
                setLoad(false)
                window.scrollTo(0, 0)
                window.setTimeout(() => {
                    isOpenAdd(false)
                    setQueryString(require("randomstring").generate({
                        length: 1,
                        charset: 'alphanumeric',
                        capitalization: 'lowercase'
                    }))
                }, 1000);
            } else {
                errorToast(response.data.message)
                window.setTimeout(() => {

                }, 2000);
            }
        })
            .catch((error) => {
                if (error.response.data.status_code === 401) {
                    logout()
                }
                errorToast(error.response.data.message)
                setLoad(false)
                window.setTimeout(() => {
                }, 2000);
            })
    }

    const tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }
        }).join('&');
        setQueryString(queryString)
    }

    return (
        <div style={{ marginTop: "-20px" }} >
            < >

                <Modal
                    isOpen={open}
                    onRequestClose={e => {
                        closeModal(e);
                    }}

                    contentLabel="My dialog"
                    className="mymodal"
                    onAfterOpen={() => {
                        document.body.style.overflow = 'hidden';
                    }}
                    onAfterClose={() => {
                        document.body.removeAttribute('style');
                    }}
                    overlayClassName="myoverlay"
                    closeTimeoutMS={500}
                >
                    <MDBCloseIcon onClick={closeModal} />
                    <h4><b>Modify SenderID Details</b></h4>
                    <br></br>
                    <>
                        <Form className="form login-form" >
                            <div className="form__form-group">
                                <div className="col-md-10-offset-1"><br />
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <label className="form-label">Sender ID Name  (Please counter check, no errors, space)</label>
                                        </div>
                                        <div className="col-md-12">
                                            <input id="input" type="text
                                                          " className="form-control"
                                                name="name" placeholder='Sender ID Name'
                                                value={name} onChange={e => setName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-10-offset-1">
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <label className="form-label">API Key</label>
                                        </div>
                                        <div className="col-md-12">
                                            <input id="input" type="text
                                                          " className="form-control"
                                                name="api_key" placeholder='Paste API Key here'
                                                value={api_key} onChange={e => setAPIKey(e.target.value)} />
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-10-offset-1">
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <label className="form-label">Access Key</label>
                                        </div>
                                        <div className="col-md-12">
                                            <input id="input" type="text
                                                          " className="form-control"
                                                name="access_key" placeholder='Paste Access Key'
                                                value={access_key} onChange={e => setAccessKey(e.target.value)} />
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-10-offset-1">
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <label className="form-label">Client ID</label>
                                        </div>
                                        <div className="col-md-12">
                                            <input id="input" type="text
                                                          " className="form-control"
                                                name="client_id" placeholder='Enter Client ID here'
                                                value={client_id} onChange={e => setClientID(e.target.value)} />
                                        </div>
                                    </div>
                                    <br />
                                </div>

                                <div className="account__btns col-8 offset-2" >
                                    <br />
                                    <Button className="account__btn" type='submit' onClick={onSubmitUpdate} color="success"> {
                                        isLoading ? "Please wait..." : "Submit"
                                    }</Button>
                                </div>
                            </div>
                        </Form>
                    </>
                </Modal>



                <Modal
                    isOpen={openAdd}
                    onRequestClose={e => {
                        closeModalAdd(e);
                    }}

                    contentLabel="My dialog"
                    className="mymodal"
                    onAfterOpen={() => {
                        document.body.style.overflow = 'hidden';
                    }}
                    onAfterClose={() => {
                        document.body.removeAttribute('style');
                    }}
                    overlayClassName="myoverlay"
                    closeTimeoutMS={500}
                >
                    <MDBCloseIcon onClick={closeModalAdd} />
                    <h4><b>Add User</b></h4>
                    <br></br>
                    <>
                        <Form className="form login-form" >
                            {/* <h5><b>Get Agent Number</b></h5> */}
                            <div className="form__form-group">
                                <br></br>
                                {/* 
                                <div className="col-md-10-offset-1">
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <label className="form-label">Customer</label>
                                        </div>
                                        <div className="col-md-12">

                                            <Select
                                                isClearable
                                                options={
                                                    (user && user.length > 0 || user.length === 0) &&
                                                    user.map((countyItem, i) => ({
                                                        label: countyItem.name,
                                                        value: countyItem.id
                                                    }))}

                                                placeholder="Select User"
                                                autosize={true}
                                                onChange={e => setUserID(e.value)}
                                                className="selected"
                                                menuPortalTarget={document.body}
                                                name="namffe"
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-md-10-offset-1"><br />
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <label className="form-label">Sender ID Name  (Please counter check, no errors, space)</label>
                                        </div>
                                        <div className="col-md-12">
                                            <input id="input" type="text
                                                          " className="form-control"
                                                name="name" placeholder='Sender ID Name'
                                                value={name} onChange={e => setName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-10-offset-1">
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <label className="form-label">API Key</label>
                                        </div>
                                        <div className="col-md-12">
                                            <input id="input" type="text
                                                          " className="form-control"
                                                name="api_key" placeholder='Paste API Key here'
                                                value={api_key} onChange={e => setAPIKey(e.target.value)} />
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-10-offset-1">
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <label className="form-label">Access Key</label>
                                        </div>
                                        <div className="col-md-12">
                                            <input id="input" type="text
                                                          " className="form-control"
                                                name="access_key" placeholder='Paste Access Key'
                                                value={access_key} onChange={e => setAccessKey(e.target.value)} />
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-10-offset-1">
                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <label className="form-label">Client ID</label>
                                        </div>
                                        <div className="col-md-12">
                                            <input id="input" type="text
                                                          " className="form-control"
                                                name="client_id" placeholder='Enter Client ID here'
                                                value={client_id} onChange={e => setClientID(e.target.value)} />
                                        </div>
                                    </div>
                                    <br />
                                </div>

                                <div className="account__btns col-8 offset-2" >
                                    <Button className="account__btn" type='submit' onClick={onSubmit} color="success"> {
                                        isLoading ? "Please wait..." : "Submit"
                                    }</Button>
                                </div>

                            </div>
                        </Form>
                    </>
                </Modal>
                < Card >
                    {ToastTable()}
                    <CardBody >
                        <Table responsive hover>
                            <div className="panel-body" >

                                <div className="row">
                                    <div className="col-md-6">
                                        <h4> <b> SenderIDs</b></h4><br />
                                    </div>
                                    <div className="col-6 pull-right">
                                        <button className="btn btn-primary btn-sm float-right"
                                            title="Add Agent"
                                            style={
                                                { marginRight: '10px' }}
                                            onClick={() => { isOpenAdd(true) }} >
                                            Add SenderID <i className="fa fa-add"></i>
                                        </button>
                                    </div>
                                </div>
                                <ReactDatatable
                                    config={config}
                                    records={senderids}
                                    columns={columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={isLoading}
                                    total_record={senderids && senderids.length > 0 ? senderids.length : 0}
                                    onChange={tableChangeHandler} />
                            </div>
                        </Table>
                    </CardBody>


                </Card>
            </>
        </div >
    )

}
export default ShortCodes;
