import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../Layout/index';
import UsersStaffs from '../../../Users/UsersStaffs';
import ShortCodes from '../../../Users/shortcodes';



export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/staffs" component={UsersStaffs} />
      <Route path="/shortcodes" component={ShortCodes} />
    </div>
  </div>
);
